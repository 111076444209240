import { PartyItem, PartyType } from "../../types/wotv-guild-data-api";
import PlayerPartyImage from "../../graphics/PlayerPartyImage";
import { useNavigate } from "react-router-dom";
import React, { startTransition } from "react";
import { DataView } from "primereact/dataview";
import { convertLocalDateArrayToString } from "../../utilities/WotvDataUtil";

export interface UnitPartiesDataViewProps {
    unitId: number,
    date?: string,
    partyType: PartyType,
    chartData: PartyItem[]
}

export const UnitPartiesDataView = (props: UnitPartiesDataViewProps) => {
    const navigate = useNavigate();

    const onNameClick = (partyItem: PartyItem) => {
        startTransition(() => {
            navigate("/player/" + partyItem.playerId);
            window.scrollTo({ top: 0, behavior: "smooth" });
        });
    };

    const onGuildClick = (guildId: number) => {
        startTransition(() => {
            navigate("/guild/" + guildId);
            window.scrollTo({ top: 0, behavior: "smooth" });
        });
    };

    const onSwordClick = (partyItem: PartyItem) => {
        startTransition(() => {
            navigate(`/guild/${partyItem.guildId}/${convertLocalDateArrayToString(partyItem.date)}/${partyItem.sessionId}`);
            window.scrollTo({ top: 0, behavior: "smooth" });
        });
    };

    const itemTemplate = (partyItem: PartyItem) => {
        return (
            <div style={{ margin: 5 }}>
                <PlayerPartyImage {...partyItem}
                                  onNameClick={onNameClick}
                                  onGuildClick={() => onGuildClick(partyItem.guildId)}
                                  onSwordClick={() => onSwordClick(partyItem)}
                ></PlayerPartyImage>
            </div>
        );
    };

    return (
        <DataView
            value={props.chartData}
            itemTemplate={(item, layout) => itemTemplate(item)}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 35, 40, 45, 50]}
            rows={10}
            paginator
            // paginatorPosition={"both"}
        ></DataView>
    );
};