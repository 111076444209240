import { selectorFamily, useRecoilValue } from "recoil";
import { findPartiesSelector } from "../../service/GuildBattleDataService";
import * as React from "react";
import { useState } from "react";
import { Fieldset } from "primereact/fieldset";
import { PlayerDataViewItem, PlayerMatchedPartiesDataView } from "../wotv/PlayerMatchedPartiesDataView";
import { PartyItem, PartyType } from "../../types/wotv-guild-data-api";
import { WotvIcon } from "../../graphics/icon/WotvIcon";
import { unitElementMapSelector } from "../../utilities/WotvDataUtil";
import { idMapSelector } from "../../service/ConfigService";
import { Dropdown } from "primereact/dropdown";
import { PartySortOption, Sort } from "./UnitPartiesDataViewDashlet";

export interface PlayerMatchedPartiesDashletProps {
    date?: string;
    guildId?: number;

    hideGuildName?: boolean;
    hideDateColumn?: boolean;
    hideEnemyGuildName?: boolean;
    hideCalendarInput?: boolean;

    showCrashes?: boolean;
    sort?: "RANK" | "DATE";

    sessionId?: number;

    playerId?: number;

    partyType: PartyType;
    hideLegend?: boolean;
    hideElementsFilter?: boolean;
    hideAbsentFilter?: boolean;
    attackFilterDefault: boolean
    defenseFilterDefault: boolean
}

export type PlayerChangeFilters = {
    date?: string;
    sessionId?: number;
    playerId?: number;
    guildId?: number;

    wipeChecked: boolean,
    absentChecked: boolean,
    flexChecked: boolean
    partyType: PartyType

    attackFilterChecked: boolean
    defendFilterChecked: boolean,

    elementFilter: boolean[]
    sort: Sort
}


export const filteredPartyMatchedItemsSelector = selectorFamily({
    key: "filteredPartyMatchedItemsSelector",
    get: (props: PlayerChangeFilters) => ({ get }) => {
        const idMap = get(idMapSelector);
        const unitElementMap = get(unitElementMapSelector);
        let parties = get(findPartiesSelector({
            playerId: props.playerId,
            sessionId: props.sessionId,
            date: props.date
        }));

        let partyIdMap: { [index: number]: PartyItem } = {};

        parties.forEach(party => {
            partyIdMap[party.partyId] = party;
        });

        let partiesMap: Map<PartyItem, PlayerDataViewItem> = new Map();

        let filteredParties = (typeof props.guildId !== "undefined" ?
            parties.filter(party => party.guildId === props.guildId) :
            parties.filter(party => party.playerId === props.playerId))
            .filter((party) => party.partyType === props.partyType);

        filteredParties.forEach(party => {
            partiesMap.set(party, {
                playerParty: party,
                matchedParties: [],
                type: party.partyType
            });

            for (const matchedParty of party.matchedParties) {
                let playerDataViewItem = partiesMap.get(party);
                playerDataViewItem!.matchedParties.push(partyIdMap[matchedParty]);
            }
        });

        let allowedPartyIds: Set<number> = new Set();

        const checkElement = ((party: PartyItem) => {
            const unit1Element = +unitElementMap[idMap[party.unit1]];
            const unit2Element = +unitElementMap[idMap[party.unit2]];
            const unit3Element = +unitElementMap[idMap[party.unit3]];
            return (props.elementFilter[unit1Element] || props.elementFilter[unit2Element] || props.elementFilter[unit3Element]  || props.elementFilter.every((active) => !active));
        });

        const checkAttackParty = (attackParty: PartyItem, attackMatchedParties: PartyItem[]) => {

            let attackFilterMatched = true;

            if (props.wipeChecked && !(attackParty.matchedParties.length === 1 && !attackParty.unit1Alive && !attackParty.unit2Alive && !attackParty.unit3Alive)) {
                attackFilterMatched = false;
            }

            if (props.absentChecked && !attackParty.absent) {
                attackFilterMatched = false;
            }

            if (props.flexChecked) {
                const clearedMatchParties = attackMatchedParties.filter((matchedParty) => {
                    if (matchedParty.matchedParties.length === 1 && !matchedParty.unit1Alive && !matchedParty.unit2Alive && !matchedParty.unit3Alive) {
                        return true;
                    }
                });

                if (clearedMatchParties.length === 0) {
                    attackFilterMatched = false;
                }
            }

            return attackFilterMatched;
        };

        const checkFilter = (party: PartyItem, matchedParties: PartyItem[]) => {
            if (party.partyType === "A") {
                let attackFilterMatched = checkAttackParty(party, matchedParties);

                let attackElementMatched = checkElement(party);

                let defendElementMatched = false;

                for (const matchedParty of matchedParties) {
                    if (checkElement(matchedParty)) {
                        defendElementMatched = true;
                    }
                }

                if (!attackFilterMatched) {
                    return false;
                }

                if (props.attackFilterChecked && props.defendFilterChecked) {
                    if (!attackElementMatched && !defendElementMatched) {
                        return false;
                    }
                } else if (props.attackFilterChecked && !props.defendFilterChecked) {
                    if (!attackElementMatched) {
                        return false;
                    }
                } else if (!props.attackFilterChecked && props.defendFilterChecked) {
                    if (!defendElementMatched) {
                        return false;
                    }
                }
            } else {
                let defendElementMatched = checkElement(party);

                let attackFilterMatched = false;
                let attackElementMatched = false;

                for (const matchedParty of matchedParties) {
                    if (checkAttackParty(matchedParty, [party])) {
                        attackFilterMatched = true;
                    }

                    if (checkElement(matchedParty)){
                        attackElementMatched = true;
                    }
                }

                if (matchedParties.length > 0 && !attackFilterMatched) {
                    return false;
                }

                if ((props.wipeChecked || props.flexChecked) && matchedParties.length === 0) {
                    return false;
                }

                if (props.attackFilterChecked && props.defendFilterChecked) {
                    if (!attackElementMatched && !defendElementMatched) {
                        return false;
                    }
                } else if (props.attackFilterChecked && !props.defendFilterChecked) {
                    if (!attackElementMatched) {
                        return false;
                    }
                } else if (!props.attackFilterChecked && props.defendFilterChecked) {
                    if (!defendElementMatched) {
                        return false;
                    }
                }
            }

            return true;

        };

        Array.from(partiesMap.values()).forEach((playerDataViewItem) => {
            let playerParty = playerDataViewItem.playerParty;

            let matchedParties = playerDataViewItem.matchedParties;

            if (checkFilter(playerParty, matchedParties)) {
                allowedPartyIds.add(playerParty.partyId);
                for (const matchedParty of matchedParties) {
                    allowedPartyIds.add(matchedParty.partyId);
                }
            }
        });

        let finalParties = parties.filter(item => {
            return allowedPartyIds.has(item.partyId);
        });

        finalParties.sort((a, b) => {
            if (props.sort === "NAME") {
                return a.playerName.localeCompare(b.playerName);
            } else {
                if (props.partyType === "A") {
                    if (a.stars === b.stars) {
                        return a.playerName.localeCompare(b.playerName);
                    } else {
                        return b.stars - a.stars;
                    }
                } else {
                    if (a.matchedParties.length === b.matchedParties.length) {
                        return a.playerName.localeCompare(b.playerName);
                    } else {
                        return b.matchedParties.length - a.matchedParties.length;
                    }
                }
            }
        });

        return finalParties;
    }
});

export const PlayerMatchedPartiesDashlet = (props: PlayerMatchedPartiesDashletProps) => {
    const [elementFilter, setElementFilter] = useState<boolean[]>([false, false, false, false, false, false, false, false, false, false]);

    const [wipeChecked, setWipeChecked] = useState(false);
    const [absentChecked, setAbsentChecked] = useState(false);
    const [flexChecked, setFlexChecked] = useState(false);

    const [attackFilterChecked, setAttackFilterChecked] = useState(props.attackFilterDefault);
    const [defenseFilterChecked, setDefenseFilterChecked] = useState(props.defenseFilterDefault);

    let sortOptions: PartySortOption[] = [
        {
            label: "Name",
            value: "NAME"
        },
        {
            label: props.partyType === "A" ? "Stars" : "Defends",
            value: "STARS"
        }
    ];

    const [sort, setSort] = useState("STARS");

    // @ts-ignore
    const sortItemTemplate = (props) => {
        return (
            <>
                <span>{props.label}</span>
            </>
        );
    };

    let parties = useRecoilValue(filteredPartyMatchedItemsSelector({
        playerId: props.playerId,
        sessionId: props.sessionId,
        date: props.date,
        guildId: props.guildId,
        partyType: props.partyType,

        wipeChecked: wipeChecked,
        absentChecked: absentChecked,
        flexChecked: flexChecked,
        attackFilterChecked: attackFilterChecked,
        defendFilterChecked: defenseFilterChecked,
        elementFilter: elementFilter,
        sort: sort as Sort
    }));

    const onElementFilterChange = (element: number) => {
        let filters = [...elementFilter];
        filters[element] = !filters[element];
        setElementFilter(filters);
    };

    return (
        <>
            <Fieldset legend={props.partyType === "A" ? "Attack Party Matchups" : "Defense Party Matchups"}>
                <div className="flex align-items-center "
                     style={{ columnGap: "10px", rowGap: "10px", flexWrap: "wrap" }}>
                    {!props.hideElementsFilter &&
                        <div className="p-inputgroup align-items-center"
                             style={{ maxWidth: "280px", columnGap: "3px" }}>
                            <span className="p-inputgroup-addon">Filters</span>
                            <WotvIcon url={"/assets/icons/Element_Icon_Fire.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(1)} disable={!elementFilter[1]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Ice.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(2)} disable={!elementFilter[2]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Wind.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(3)} disable={!elementFilter[3]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Earth.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(4)} disable={!elementFilter[4]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Thunder.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(5)} disable={!elementFilter[5]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Water.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(6)} disable={!elementFilter[6]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Shine.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(7)} disable={!elementFilter[7]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Dark.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(8)} disable={!elementFilter[8]} />
                        </div>
                    }
                    <div className="p-inputgroup" style={{ maxWidth: "100px" }}>
                        <WotvIcon url={"/assets/icons/toilet_paper.png"} height={22} width={30} title="1st attack failures"
                                  onClick={() => {
                                      if (!wipeChecked) {
                                          setAbsentChecked(false);
                                          setFlexChecked(false);
                                      }

                                      setWipeChecked(!wipeChecked);
                                  }} disable={!wipeChecked} />
                        {!props.hideAbsentFilter &&
                        <div style={{ marginLeft: 5 }}>
                            <WotvIcon url={"/assets/icons/absent_icon.png"} height={22} width={22} title="Absent / missed attacks"
                                      onClick={() => {
                                          if (!absentChecked) {
                                              setWipeChecked(false);
                                              setFlexChecked(false);
                                          }

                                          setAbsentChecked(!absentChecked);

                                      }} disable={!absentChecked} />
                        </div>
                        }
                        <div style={{ marginLeft: 10 }}>
                            <WotvIcon url={"/assets/icons/flex_icon.png"} height={20} width={20} title="Fresh team clears"
                                      onClick={() => {
                                          if (!flexChecked) {
                                              setWipeChecked(false);
                                              setAbsentChecked(false);
                                          }

                                          setFlexChecked(!flexChecked);
                                      }} disable={!flexChecked} />
                        </div>
                    </div>
                    <div className="p-inputgroup align-items-center" style={{ maxWidth: "220px" }}>
                        <span className="p-inputgroup-addon"> Elemental Filters for</span>

                        <div style={{ marginLeft: 10 }}>
                            <WotvIcon url={"/assets/gb/guildbattle_icon_battle_amount.png"} height={25} width={25}
                                      onClick={() => setAttackFilterChecked(!attackFilterChecked)}
                                      disable={!attackFilterChecked} />
                        </div>

                        <div style={{ marginLeft: 10 }}>
                            <WotvIcon url={"/assets/icons/shield.png"} height={25} width={25}
                                      onClick={() => setDefenseFilterChecked(!defenseFilterChecked)}
                                      disable={!defenseFilterChecked} />
                        </div>
                    </div>

                    <div className="p-inputgroup align-items-center"
                         style={{ maxWidth: "165px" }}>
                        <span className="p-inputgroup-addon">Sort</span>
                        <Dropdown optionLabel="label" optionValue="value" value={sort} options={sortOptions}
                                  onChange={(e) => setSort(e.value)}
                                  itemTemplate={sortItemTemplate}
                                  placeholder="Sort" />
                    </div>

                </div>
            </Fieldset>
            <PlayerMatchedPartiesDataView guildId={props.guildId} sessionId={props.sessionId} playerId={props.playerId}
                                          date={props.date} partyType={props.partyType} parties={parties}
                                          hideLegend={!props.playerId} />
        </>);
};