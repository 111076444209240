import React from "react";
import { PartyImage } from "./PartyImage";
import StarImage from "./icon/StarImage";
import { PartyItem } from "../types/wotv-guild-data-api";
import { PositionImage } from "./icon/PositionImage";
import { ShieldImage } from "./icon/ShieldImage";
import { WotvIcon } from "./icon/WotvIcon";

export interface PartyItemExtendedImage extends PartyItem {
    onNameClick?: (partyItem: PartyItem) => void;
    onGuildClick?: (partyItem: PartyItem) => void;
    onSwordClick?: (partyItem: PartyItem) => void;
    guildRank?: number;
}

const PlayerPartyImage = (props: PartyItemExtendedImage) => {

    const onNameClick = props.onNameClick || (() => {
    });
    const onGuildClick = props.onGuildClick || (() => {
    });
    const onSwordClick = props.onSwordClick || (() => {
    });

    return <div style={{
        height: "85px",
        width: "20px",
        minWidth: "210px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "210px 85px",
        margin: "0px",
        padding: "0px",
        borderSpacing: "0px",
        // paddingLeft: "20px",
        backgroundImage: props.partyType === "A" ? "url(/assets/gb/guildbattle_list_player_wide_v3.png)" : "url(/assets/gb/guildbattle_list_enemy_wide_v3.png)"
        // backgroundColor: partyItem.partyType === "A" ? "rgba(51,73,255,0.40)" : "rgba(255,51,51,0.4)",
        // borderRadius: "10px"
    }}>
        <div className="flex justify-content-between cursor-pointer" style={{ padding: "4px 13px 0px 13px" }}>
                    <span
                        onClick={(event) => onNameClick(props)}
                        style={{
                            width: "240px",
                            lineHeight: "20px",
                            fontWeight: 500,
                            color: "white",
                            marginLeft: "0px",
                            fontSize: "13px"
                            // width: "110px",
                        }}>{props.playerName}<span style={{ marginLeft: "5px" }}><PositionImage
                        guildPosition={props.guildPosition} /></span>
                    </span>
        </div>
        <div className={"flex justify-content-start"} style={{ padding: "4px 0px 0px 15px" }}>
            <div style={{ width: "147px" }}><PartyImage {...props} /></div>

            <div className={"flex flex-column"} style={{ marginTop: props.guildRank ? -5 : 0 }}>

                {props.partyType === "D" && <div style={{ marginLeft: 2, marginTop: 10,
                    cursor: props.onSwordClick ? "pointer" : "default" }}
                                                 onClick={() => onSwordClick(props)}><ShieldImage /><span
                    style={{ marginLeft: "5px", fontWeight: 500 }}>{props.matchedParties.length}</span></div>}

                {props.partyType === "A" &&
                    <div><StarImage /><span style={{ marginLeft: 4, fontWeight: 500 }}>{props.stars}</span></div>}

                {props.partyType === "A" && props.absent &&
                    <div style={{ marginLeft: "5px" }}><WotvIcon url={"/assets/icons/absent_icon.png"} height={16}
                                                                 width={16} />
                    </div>}

                {props.partyType === "A" && !props.absent && <div style={{
                    cursor: props.onSwordClick ? "pointer" : "default"
                }}
                    onClick={() => onSwordClick(props)}>
                    <WotvIcon url={"/assets/gb/guildbattle_icon_battle_amount.png"} height={15} width={15}/>
                    <span style={{ marginLeft: "2px", fontWeight: 500 }}>{2 - props.matchedParties.length}/2</span>
                </div>}

                {props.guildRank && <div className={"flex align-items-center"}
                                         style={{
                                             marginTop: props.partyType === "A" ? "0px" : "3px",
                                             cursor: "pointer"
                                         }}

                                         onClick={(event) => onGuildClick(props)}
                ><WotvIcon url={"/assets/icons/mili_rank.png"} height={15} width={15} title={"Guild Rank"} /><span
                    style={{
                        marginLeft: props.partyType === "A" ? "4px" : "6px",
                        fontWeight: 500
                    }}>{props.guildRank}</span></div>}
            </div>
        </div>
    </div>;
};

export default PlayerPartyImage;