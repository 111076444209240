import { PartyItem, PartyType } from "../../types/wotv-guild-data-api";
import React from "react";
import { unitElementMapSelector } from "../../utilities/WotvDataUtil";
import { selectorFamily, useRecoilValue } from "recoil";
import { FindPartiesRequest, findPartiesSelector } from "../../service/GuildBattleDataService";
import { GuildBattleMetaChart, GuildBattleMetaChartItem } from "../chart/GuildBattleMetaChart";
import { idMapSelector } from "../../service/ConfigService";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";

export interface GuildBattleMetaChartDashletProps {
    guildId: number;
    sessionId: number;
    date?: string;

    partyType: PartyType;
    hideLegend?: boolean;
}

export type PlayerDataViewItem = {
    playerParty: PartyItem
    matchedParties: PartyItem[]
    type: PartyType
}

export type  guildBattleMetaChartDataRequest = FindPartiesRequest & {
    partyType: PartyType
    guildId: number
}

export const guildBattleMetaChartDataSelector = selectorFamily({
    key: "guildBattleMetaChartDataSelector", get: (props: guildBattleMetaChartDataRequest) => ({ get }) => {
        let parties = get(findPartiesSelector({
            sessionId: props.sessionId,
            date: props.date
        }));

        let idMap = get(idMapSelector);
        let unitElementMap = get(unitElementMapSelector);

        let countMap: Map<string, number> = new Map();

        let filteredParties = parties.filter((party) => {
            return party.partyType === props.partyType && party.guildId === props.guildId;
        });

        let numUnrevealed = 0;
        let numUnits = 0;

        const countKey = (id: number, countMap: Map<string, number>) => {
            let key = idMap[id];
            if (key !== "~UNREVEALED~") {
                numUnits++;
                if (countMap.has(key)) {
                    countMap.set(key, countMap.get(key)! + 1);
                } else {
                    countMap.set(key, 1);
                }
            } else {
                numUnrevealed++;
            }
        };

        filteredParties.forEach((party) => {
            countKey(party.unit1, countMap);
            countKey(party.unit2, countMap);
            countKey(party.unit3, countMap);
        });

        let guildBattleMetaChartItem: GuildBattleMetaChartItem = {
            numTeams: numUnits,
            numUnrevealed: numUnrevealed
        };

        countMap.forEach((value, key) => {
            // @ts-ignore
            guildBattleMetaChartItem[key] = value;
        });

        let keys = Array.from(countMap.keys());

        keys.sort((a, b) => {

            let aCount = countMap.get(a)!;
            let bCount = countMap.get(b)!;

            if (aCount !== bCount) {
                return bCount - aCount;
            } else {
                return a.localeCompare(b);
            }
        });

        const offsetMap: Map<string, number> = new Map();

        const elementOffsetMap: Map<number, number> = new Map();

        keys.forEach((key) => {
            if (countMap.get(key)! <= 3) {

                let elementNumber = +unitElementMap[key];

                if (!elementOffsetMap.has(elementNumber)) {
                    elementOffsetMap.set(elementNumber, 0);
                }

                let currentElementOffset = elementOffsetMap.get(elementNumber)! + 1;

                elementOffsetMap.set(elementNumber, currentElementOffset);

                offsetMap.set(key, currentElementOffset);
            }
        });

        return {
            guildBattleMetaChartItem: guildBattleMetaChartItem,
            keys: Array.from(keys.reverse()),
            offsetMap: offsetMap
        };
    }
});

const GuildBattleMetaChartDashlet = (props: GuildBattleMetaChartDashletProps) => {

    let data = useRecoilValue(guildBattleMetaChartDataSelector({
        sessionId: props.sessionId,
        date: props.date,
        partyType: props.partyType,
        guildId: props.guildId
    }));

    return (
        <>
            <Fieldset legend={props.partyType === "A" ? "Attack Unit Distribution" : "Defense Unit Distribution"}>
                <div className="flex justify-content-start"
                     style={{ columnGap: "10px", rowGap: "10px", flexWrap: "wrap" }}>
                    <div className="p-inputgroup" style={{ maxWidth: "125px" }}>
                        <span className="p-inputgroup-addon">
                            Units
                        </span>
                        <InputText value={data.guildBattleMetaChartItem.numTeams} readOnly></InputText>
                    </div>
                    <div className="p-inputgroup" style={{ maxWidth: "125px" }}>
                        <span className="p-inputgroup-addon">
                            Unrevealed
                        </span>
                        <InputText value={data.guildBattleMetaChartItem.numUnrevealed} readOnly></InputText>
                    </div>
                </div>
            </Fieldset>
            <GuildBattleMetaChart
                data={data.guildBattleMetaChartItem}
                keys={data.keys}
                offsetMap={data.offsetMap}
            />
        </>
    );
};

export default GuildBattleMetaChartDashlet;