import {atom, selector, selectorFamily} from "recoil";
import {dataReadyDatesSelector} from "./ConfigService";
import {format} from "date-fns";
import {apiUrl} from "../Config";
import {
    GuildBattleItem,
    GuildRosterChangeItem,
    GuildRosterItem,
    PartyItem,
    PlayerBattleHistoryItem, PositionType
} from "../types/wotv-guild-data-api";
import {convertLocalDateArrayToDate} from "../utilities/WotvDataUtil";

export const filterSelectedDateAtom = atom({
    key: 'filterSelectedDateAtom',
    default: selector({
        key: 'filterSelectedDateAtom/Default',
        get: ({get}) => format(get(dataReadyDatesSelector)[0], "yyyy-MM-dd"),
    }),
});

export const guildRankingData = selectorFamily({
    key: 'guildRankingData',
    get: (date: string) => ({get}) => {

        return fetch(apiUrl() + "/guild/ranking/" + date)
            .then(response => response.json())
            .then((guildBattleItems: GuildBattleItem[]) => {

                guildBattleItems.sort((a, b) => a.guildRank - b.guildRank);

                return guildBattleItems;
            })
    }
});
export type GuildBattleRequestProps = {
    guildId: number
    date?: string
}

export const guildBattleByGuildId = selectorFamily({
    key: 'guildBattleByGuildId',
    get: ({guildId, date}: GuildBattleRequestProps) => ({get}) => {
        if (guildId === 0) {
            let rankingDate = date;
            if (typeof rankingDate === "undefined") {
                rankingDate = get(filterSelectedDateAtom);
            }
            return get(guildRankingData(rankingDate));
        }

        return fetch(apiUrl() + "/guild/" + guildId)
            .then(response => response.json())
            .then((guildBattleItems: GuildBattleItem[]) => {

                guildBattleItems.sort((b, a) => {
                    let date1 = convertLocalDateArrayToDate(a.date);
                    let date2 = convertLocalDateArrayToDate(b.date);
                    return +date1 - +date2;
                });

                return guildBattleItems;
            })
    }
});

export interface PlayerChangeRowItem {
    date: Date,
    guildId: number,
    guildName: string
    guildRank: number,
    playerId: number,
    playerName: string,
    playerPosition: PositionType,
    playerPositionOld?: string,
    guildAction: "JOINED" | "LEFT" | "POSITION_CHANGED" | "NAME_CHANGED"
}

const convertToChanges = (guildRosterChangeItems: GuildRosterChangeItem[]) => {
    guildRosterChangeItems.sort((a, b) => a.guildRank - b.guildRank);

    let totalChanges: PlayerChangeRowItem[] = [];

    guildRosterChangeItems.forEach((roster) => {
        const changes = extractChanges(roster);

        totalChanges.push(...changes);
    });

    return totalChanges;
};

const extractChanges = (roster: GuildRosterChangeItem) => {

    let changes: PlayerChangeRowItem[] = [];

    if (roster.playersLeft != null) {
        for (const playerSummary of roster.playersLeft) {
            changes.push({
                date: convertLocalDateArrayToDate(playerSummary.date),
                guildId: roster.guildId,
                guildName: roster.guildName,
                guildRank: roster.guildRank,
                playerId: playerSummary.playerId,
                playerName: playerSummary.name,
                playerPosition: playerSummary.position,
                guildAction: "LEFT"
            })
        }
    }

    if (roster.playersJoined != null) {
        for (const playerSummary of roster.playersJoined) {
            changes.push({
                date: convertLocalDateArrayToDate(playerSummary.date),
                guildId: roster.guildId,
                guildName: roster.guildName,
                guildRank: roster.guildRank,
                playerId: playerSummary.playerId,
                playerName: playerSummary.name,
                playerPosition: playerSummary.position,
                guildAction: "JOINED"
            })
        }
    }

    if (roster.positionChanges.length > 0) {
        for (const playerSummary of roster.positionChanges) {
            changes.push({
                date: convertLocalDateArrayToDate(playerSummary.date),
                guildId: roster.guildId,
                guildName: roster.guildName,
                guildRank: roster.guildRank,
                playerId: playerSummary.playerId,
                playerName: playerSummary.name,
                playerPosition: playerSummary.position,
                playerPositionOld: playerSummary.oldPosition,
                guildAction: "POSITION_CHANGED"
            })
        }
    }

    return changes;
};

export type GuildRosterChangeRequest = {
    guildId: number,
    date?: string
}

export const playerChangeRowItemsByGuildId = selectorFamily({
    key: 'playerChangeRowItemsByGuildId',
    get: ({guildId = 0, date}: GuildRosterChangeRequest) => ({get}) => {

        if (guildId === 0) {
            return get(guildRosterChangeItemsByDate(date!));
        }

        return fetch(apiUrl() + "/guild/roster/changes/" + guildId)
            .then(response => response.json())
            .then(guildRosterItems => {
                return convertToChanges(guildRosterItems) as PlayerChangeRowItem[];
            })
    }
});

export type GuildRosterRequest = {
    guildId: number,
    date: string,
}

export const guildRosterByGuildIdAndDateSelector = selectorFamily({
    key: 'guildRosterByGuildIdAndDateSelector',
    get: (props: GuildRosterRequest) => ({get}) => {
        return fetch(apiUrl() + "/guild/roster/" + props.guildId + "/" + props.date)
            .then(response => response.json())
            .then(guildRosterItem => {
                return guildRosterItem as GuildRosterItem
            })
    }
});

export const guildRosterChangeItemsByDate = selectorFamily({
    key: 'guildRosterChangeItemsByDate',
    get: (date: string) => ({get}) => {
        return fetch(apiUrl() + "/guild/roster/changes/date/" + date)
            .then(response => response.json())
            .then(guildRosterItems => {
                return convertToChanges(guildRosterItems);
            })
    }
});

export type FindPartiesRequest = {
    sessionId?: number,
    date?: string,
    playerId?: number
}

export const findPartiesSelector = selectorFamily({
    key: 'findPartiesSelector',
    get: ({sessionId, date, playerId}: FindPartiesRequest) => ({get}) => {

        let partyUrl;

        if (typeof playerId !== "undefined") {
            partyUrl = apiUrl() + "/player/" + playerId + "/history";
            return fetch(partyUrl)
                .then(response => response.json())
                .then((playerBattleHistoryItems: PlayerBattleHistoryItem[]) => {

                    let partyItems: PartyItem[] = [];

                    playerBattleHistoryItems.forEach((playerBattleHistoryItem) => {
                        Object.values(playerBattleHistoryItem.partyItemMap).forEach((partyItemList) => {
                            partyItemList.forEach(partyItem => {
                                partyItems.push(partyItem);
                            })
                        })
                    })

                    return partyItems;
                })
        } else {
            partyUrl = apiUrl() + "/party/" + sessionId + "/" + date;
            return fetch(partyUrl)
                .then(response => response.json())
                .then(partyItems => {
                    return partyItems as PartyItem[]
                })
        }
    }
});
