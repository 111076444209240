import * as React from "react";
import { useState, useTransition } from "react";
import { PartyType, UnitMetaItem } from "../../types/wotv-guild-data-api";
import { selectorFamily, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE, useSetRecoilState } from "recoil";
import { dataReadyDatesSelector, idMapSelector } from "../../service/ConfigService";
import {
    convertLocalDateStringToDate,
    convertToLocalDateString,
    fetchGzip,
    unitElementMapSelector
} from "../../utilities/WotvDataUtil";
import { apiUrl } from "../../Config";
import { filterSelectedDateAtom, guildRankingData } from "../../service/GuildBattleDataService";
import { UnitGuildRankUsageChart } from "../chart/UnitGuildRankUsageChart";
import { ReadyDateCalendarInput } from "../wotv/ReadyDateCalendarInput";
import { Fieldset } from "primereact/fieldset";
import { CalendarValueType } from "primereact/calendar";
import { useNavigate } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import { WotvIcon } from "../../graphics/icon/WotvIcon";
import { useDebounce } from "react-use";


export interface UnitGuildRankUsageChartItem {
    unitIname: string;
    element: number;
    numTeams: number;
    numAbsent: number;
    numUnrevealed: number;
    minGuildRank: number;
    maxGuildRank: number;
    count: number;
}

export interface UnitGuildRankUsageChartDashletProps {
    unitId: number,
    partyType: PartyType,
    title?: string,
    date?: string,

    chartHeight?: number
}


export type UnitDailyMetaRequest = {
    date?: string,
    unitId: number,
}

export type UnitDailyMetaChartDataRequest = {
    date?: string,
    unitId: number,
    partyType: PartyType,

    minGuildRank?: number
    maxGuildRank?: number

    absentFilterChecked?: boolean
    attackFilterChecked?: boolean
    defenseFilterChecked?: boolean
}

export const unitDailyMetaSelector = selectorFamily({
    key: "unitDailyMetaSelector",
    get: (props: UnitDailyMetaRequest) => ({ get }) => {
        let dataReadyDates = get(dataReadyDatesSelector);
        let latestDate = dataReadyDates[0];
        let date = props.date ? convertLocalDateStringToDate(props.date) : latestDate;

        return fetchGzip(apiUrl() + "/unitmeta/" + props.unitId + "/" + convertToLocalDateString(date))
            // @ts-ignore
            .then((unitMetaItems: UnitMetaItem[]) => {
                return unitMetaItems;
            });
    }
});

export const unitDailyMetaChartDataSelector = selectorFamily({
    key: "unitDailyMetaChartDataSelector",
    get: (props: UnitDailyMetaChartDataRequest) => ({ get }) => {

        let dataReadyDates = get(dataReadyDatesSelector);
        const unitElementMap = get(unitElementMapSelector);
        let latestDate = dataReadyDates[0];
        let date = props.date ? convertLocalDateStringToDate(props.date) : latestDate;

        let unitMetaItems = get(unitDailyMetaSelector({ date: props.date, unitId: props.unitId }));

        let minGuildRank = props.minGuildRank || 1;
        let maxGuildRank = props.maxGuildRank || 20;

        let battleItems = get(guildRankingData(convertToLocalDateString(date)));

        const idMap = get(idMapSelector);

        const guildRankMap: Map<number, number> = new Map();

        for (const battleItem of battleItems) {
            guildRankMap.set(battleItem.guildId, battleItem.guildRank);
        }


        let minRange = 999;
        let maxRange = 0;

        let chartDataMap: Map<number, UnitGuildRankUsageChartItem> = new Map();

        for (const unitMetaItem of unitMetaItems) {
            if (unitMetaItem.partyType !== props.partyType) {
                continue;
            }

            for (const partyItem of unitMetaItem.partyItems) {
                if (!props.absentFilterChecked && partyItem.absent) {
                    continue;
                }

                const guildRank = guildRankMap.get(partyItem.guildId)!;

                if (guildRank < minGuildRank || guildRank > maxGuildRank) {
                    continue;
                }

                minRange = Math.min(minRange, guildRank);
                maxRange = Math.max(maxRange, guildRank);
            }
        }

        minRange = Math.floor(minRange / 10) * 10;
        maxRange = Math.ceil(maxRange / 10) * 10;

        const maxBars = 50;
        const minBinSize = (maxRange - minRange) / maxBars;

        const binSizes = [1, 2, 3, 4, 5, 10, 20, 30, 40, 50];

        let binSize = 1;

        for (const size of binSizes) {
            if (size >= minBinSize) {
                binSize = size;
                break;
            }
        }

        let count = 0;

        let chartDataItems: UnitGuildRankUsageChartItem[] = [];

        let chartDataItem: UnitGuildRankUsageChartItem;

        for (let i = minGuildRank; i <= maxGuildRank; i++) {

            if (count % binSize === 0) {
                chartDataItem = {
                    unitIname: idMap[props.unitId],
                    element: +unitElementMap[idMap[props.unitId]],
                    numTeams: 0,
                    numAbsent: 0,
                    numUnrevealed: 0,
                    minGuildRank: i,
                    maxGuildRank: i + binSize - 1,
                    count: 0
                };
                chartDataItems.push(chartDataItem);
            }

            count++;
            chartDataMap.set(i, chartDataItem!);
        }


        for (const unitMetaItem of unitMetaItems) {
            if (unitMetaItem.partyType !== props.partyType) {
                continue;
            }

            for (const partyItem of unitMetaItem.partyItems) {
                if (!props.absentFilterChecked && partyItem.absent) {
                    continue;
                }

                const guildRank = guildRankMap.get(partyItem.guildId)!;

                if (guildRank < minGuildRank || guildRank > maxGuildRank) {
                    continue;
                }

                let chartItem = chartDataMap.get(guildRank)!;

                chartItem.numTeams++;

                chartItem.count++;

                chartItem.numAbsent += partyItem.absent ? 1 : 0;
                chartItem.numUnrevealed += partyItem.unrevealed ? 1 : 0;
            }
        }

        return chartDataItems;
    }
});


export const UnitGuildRankUsageChartDashlet = (props: UnitGuildRankUsageChartDashletProps) => {
    const navigate = useNavigate();
    const [inTransition, startTransition] = useTransition();

    const [minRank, setMinRank] = useState<number>(1);
    const [maxRank, setMaxRank] = useState<number>(500);
    const [debouncedMinRank, setDebouncedMinRank] = useState<number>(1);
    const [debouncedMaxRank, setDebouncedMaxRank] = useState<number>(500);
    const [absentChecked, setAbsentChecked] = useState(true);

    const title = props.title || (props.partyType === "A" ? "Guild Rank Distribution - Attack Teams" : "Guild Rank Distribution - Defense Teams");

    const [, cancel] = useDebounce(
        () => {
            startTransition(() => {
                if (minRank > maxRank) {
                    setMinRank(maxRank);
                }
                setDebouncedMinRank(Math.min(maxRank, minRank));
            });
        },
        750,
        [minRank]
    );

    const [, cancel2] = useDebounce(
        () => {
            startTransition(() => {
                if (maxRank < minRank) {
                    setMaxRank(minRank);
                }
                setDebouncedMaxRank(Math.max(maxRank, minRank));
            });
        },
        750,
        [maxRank]
    );


    let selectedDate = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(filterSelectedDateAtom);
    let setSelectedDate = useSetRecoilState(filterSelectedDateAtom);

    const onCalendarChange = (calendarValue: CalendarValueType): calendarValue is Date => {
        let calendarDate = calendarValue as Date;
        startTransition(() => {
            setSelectedDate(convertToLocalDateString(calendarDate));
        });
        return true;
    };

    let chartData = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(unitDailyMetaChartDataSelector({
        date: selectedDate,
        unitId: props.unitId,
        minGuildRank: debouncedMinRank,
        maxGuildRank: debouncedMaxRank,
        absentFilterChecked: absentChecked,
        partyType: props.partyType
    }));

    return (
        <>
            <Fieldset legend={title}>
                <div className="flex justify-content-start"
                     style={{ columnGap: "10px", rowGap: "10px", flexWrap: "wrap" }}>
                    <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                        <span className="p-inputgroup-addon">
                            Date
                        </span>
                        <ReadyDateCalendarInput date={selectedDate} onChange={onCalendarChange} />
                    </div>
                    <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                        <span className="p-inputgroup-addon">Max Guild Rank</span>
                        <InputNumber value={maxRank} min={1} max={500} showButtons
                                     onChange={(e) => setMaxRank(e.value!)}></InputNumber>
                    </div>
                    <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                        <span className="p-inputgroup-addon">Min. Guild Rank</span>
                        <InputNumber value={minRank} min={1} max={500} showButtons
                                     onChange={(e) => setMinRank(e.value!)}></InputNumber>
                    </div>
                    {props.partyType === "A" &&
                        <div className="p-inputgroup  align-items-center" style={{ maxWidth: "80px" }}>
                            <span className="p-inputgroup-addon">Filters</span>
                            <div style={{ marginLeft: 10 }}>
                                <WotvIcon url={"/assets/icons/absent_icon.png"} height={22} width={22}
                                          title="Absent / missed attacks"
                                          onClick={() => {
                                              setAbsentChecked(!absentChecked);
                                          }}
                                          disable={!absentChecked} />
                            </div>
                        </div>
                    }
                </div>
            </Fieldset>
            <UnitGuildRankUsageChart chartHeight={props.chartHeight} chartData={chartData}
                                     partyType={props.partyType} />
        </>
    );
};