import * as React from "react";
import { idMapSelector } from "../service/ConfigService";
import { useRecoilValue } from "recoil";
import { UnitImage } from "./UnitImage";

export type PartyImageProps = {
    unit1: number
    unit2: number
    unit3: number

    unit1Alive: boolean
    unit2Alive: boolean
    unit3Alive: boolean

    scrollToTopOnClick?: boolean
}

export const PartyImage = (props: PartyImageProps) => {

    const idMap = useRecoilValue(idMapSelector);

    return (<div style={{ display: "inline-block" }}>
        <UnitImage unitIname={idMap[props.unit1]} height={70} width={70} dead={!props.unit1Alive} marginLeft={-20}
                   marginRight={-20} marginTop={-10} scrollToTopOnClick={props.scrollToTopOnClick} />
        <UnitImage unitIname={idMap[props.unit2]} height={70} width={70} dead={!props.unit2Alive} marginLeft={0}
                   marginRight={-20} marginTop={-10} scrollToTopOnClick={props.scrollToTopOnClick} />
        <UnitImage unitIname={idMap[props.unit3]} height={70} width={70} dead={!props.unit3Alive} marginLeft={0}
                   marginRight={-20} marginTop={-10} scrollToTopOnClick={props.scrollToTopOnClick} />
    </div>);
};