import * as React from "react";
import { useTransition } from "react";
import { unitImageMapSelector, unitNameDataAtom } from "../utilities/WotvDataUtil";
import { useNavigate } from "react-router-dom";
import { unitMapAtom } from "../service/ConfigService";
import { useRecoilValue, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from "recoil";

export interface UnitImageProps {
    unitIname: string;
    width?: number;
    height?: number;
    dead?: boolean;
    marginRight?: number;
    marginTop?: number;
    marginLeft?: number;
    marginBottom?: number;
    scrollToTopOnClick?: boolean;
}

export const UnitImage = ({
                              unitIname,
                              width = 70,
                              height = 70,
                              marginLeft = -10,
                              marginTop = -10,
                              marginRight = 0,
                              marginBottom = 0,
                              dead = false,
                              scrollToTopOnClick = true
                          }: UnitImageProps) => {
    const navigate = useNavigate();
    const [inTransition, startTransition] = useTransition();

    const unitMap = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(unitMapAtom);
    const unitNameMap = useRecoilValue(unitNameDataAtom);

    const unitImageMap = useRecoilValue(unitImageMapSelector);

    const noData = !unitMap.hasOwnProperty(unitIname);

    const onClick = () => {
        if (noData) {
            return;
        }
        startTransition(() => {
            navigate(`/unit/${unitMap[unitIname]}`);
            if (scrollToTopOnClick) {
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        });
    };

    const unitName = unitNameMap[unitIname];

    let imageUrl;

    if (unitIname === "~UNREVEALED~") {
        imageUrl = "/assets/icons/unrevealed_silhouette.png";
    } else if (!unitImageMap.hasOwnProperty(unitIname)) {
        // console.log(unitIname + " image missing");
        imageUrl = "/assets/icons/image_coming_soon.png";
    } else {
        imageUrl = unitImageMap[unitIname];
    }

    return <img className={(!noData ? "cursor-pointer" : "default")} src={imageUrl} style={{
        filter: `brightness(${(dead || noData) ? "60%" : "100%"})`,
        width: width,
        height: height,
        marginLeft: marginLeft,
        marginTop: marginTop,
        marginBottom: marginBottom,
        marginRight: marginRight
    }}
                alt={noData ? `${unitName} - No Data` : `${unitName}`}
                title={noData ? `${unitName} - No Data` : `${unitName}`}
                onClick={() => onClick()}
    />;
};