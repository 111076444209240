import {selectorFamily} from "recoil";
import {apiUrl} from "../Config";
import {SearchResponse} from "../types/wotv-guild-data-api";

export type SearchRequestProps = {
    query: string
}
export const searchSelector = selectorFamily({
    key: 'searchSelector',
    get: (props: SearchRequestProps) => ({get}) => {

        return fetch(apiUrl() + "/search/?query=" + encodeURIComponent(props.query.trim()))
            .then(response => response.json())
            .then((searchResponse: SearchResponse) => {
                return searchResponse;
            })
    }
})