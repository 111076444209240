import React from "react";
import { useParams } from "react-router-dom";
import { GuildBattleInfoDashlet } from "../components/dashlet/GuildBattleInfoDashlet";
import GuildBattleMetaChartDashlet from "../components/dashlet/GuildBattleMetaChartDashlet";
import { PlayerMatchedPartiesDashlet } from "../components/dashlet/PlayerMatchedPartiesDashlet";

export const GuildBattlePage = () => {

    let { guildId, sessionId, date } = useParams();
    let guildIdNumber = +guildId!;
    let sessionIdNumber = +sessionId!;

    return (
        <div className="layout-dashboard" style={{ marginTop: "5px" }}>
            <div className="grid">
                <div className="col-12">
                    <div className="card widget-table">
                        <GuildBattleInfoDashlet guildId={guildIdNumber} date={date}/>
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table">
                        <GuildBattleMetaChartDashlet guildId={guildIdNumber} date={date} sessionId={sessionIdNumber} partyType={"A"}/>
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table">
                        <GuildBattleMetaChartDashlet guildId={guildIdNumber} date={date} sessionId={sessionIdNumber} partyType={"D"}/>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card widget-table">
                        <PlayerMatchedPartiesDashlet guildId={guildIdNumber} sessionId={sessionIdNumber}
                                                      date={date} partyType={"A"} hideLegend attackFilterDefault={true} defenseFilterDefault={false} />
                    </div>
                </div>
                <div className="col-12">
                    <div className="card widget-table">
                        <PlayerMatchedPartiesDashlet guildId={guildIdNumber} sessionId={sessionIdNumber}
                                                      date={date} partyType={"D"} hideLegend hideAbsentFilter attackFilterDefault={false} defenseFilterDefault={true} />
                    </div>
                </div>
            </div>
        </div>

    );
};
