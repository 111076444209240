import { PartyItem, PartyType } from "../../types/wotv-guild-data-api";
import { PartyItemExtendedImage } from "../../graphics/PlayerPartyImage";
import { useNavigate } from "react-router-dom";
import React, { startTransition, useState } from "react";
import { convertLocalDateStringToDate, convertToLocalDateString } from "../../utilities/WotvDataUtil";
import { selectorFamily, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE, useSetRecoilState } from "recoil";
import { filterSelectedDateAtom, guildRankingData } from "../../service/GuildBattleDataService";
import { dataReadyDatesSelector } from "../../service/ConfigService";
import { unitDailyMetaSelector } from "../dashlet/UnitGuildRankUsageChartDashlet";
import { CalendarValueType } from "primereact/calendar";
import { UnitPartiesDataView } from "../wotv/UnitPartiesDataView";
import { Fieldset } from "primereact/fieldset";
import { ReadyDateCalendarInput } from "../wotv/ReadyDateCalendarInput";
import { WotvIcon } from "../../graphics/icon/WotvIcon";
import { Dropdown } from "primereact/dropdown";

export interface UnitPartiesDataViewDashletProps {
    unitId: number,
    date?: string,
    title?: string,
    partyType: PartyType,

    hideAbsentFilter?: boolean
}

export type UnitPartyDataRequest = {
    date?: string,
    unitId: number,

    minGuildRank?: number
    maxGuildRank?: number

    absentChecked?: boolean
    wipeChecked?: boolean
    flexChecked?: boolean

    partyType: PartyType
    sort: Sort
}


export const unitPartiesDataSelector = selectorFamily({
    key: "unitPartiesDataSelector",
    get: (props: UnitPartyDataRequest) => ({ get }) => {

        let dataReadyDates = get(dataReadyDatesSelector);
        let latestDate = dataReadyDates[0];
        let date = props.date ? convertLocalDateStringToDate(props.date) : latestDate;

        let unitMetaItems = get(unitDailyMetaSelector({
            date: props.date,
            unitId: props.unitId
        }));

        let battleItems = get(guildRankingData(convertToLocalDateString(date)));

        const guildRankMap: Map<number, number> = new Map();

        for (const battleItem of battleItems) {
            guildRankMap.set(battleItem.guildId, battleItem.guildRank);
        }

        let unitMetaItemsMutable = [...unitMetaItems];

        for (const unitMetaItem of unitMetaItemsMutable) {
            const partyType = unitMetaItem.partyType;

            if (partyType !== props.partyType) {
                continue;
            }

            const matchedPartyMap: Map<number, PartyItem> = new Map();

            unitMetaItem.matchedPartyItems.forEach((partyItem) => matchedPartyMap.set(partyItem.partyId, partyItem));

            const partyItems: PartyItemExtendedImage[] = [];

            const checkAttackParty = (attackParty: PartyItem, attackMatchedParties: PartyItem[]) => {

                let attackFilterMatched = true;

                if (props.wipeChecked && !(attackParty.matchedParties.length === 1 && !attackParty.unit1Alive && !attackParty.unit2Alive && !attackParty.unit3Alive)) {
                    attackFilterMatched = false;
                }

                if (props.absentChecked && !attackParty.absent) {
                    attackFilterMatched = false;
                }

                if (props.flexChecked) {
                    const clearedMatchParties = attackMatchedParties.filter((matchedParty) => {
                        if (matchedParty.matchedParties.length === 1 && !matchedParty.unit1Alive && !matchedParty.unit2Alive && !matchedParty.unit3Alive) {
                            return true;
                        }
                    });

                    if (clearedMatchParties.length === 0) {
                        attackFilterMatched = false;
                    }
                }

                return attackFilterMatched;
            };

            for (const partyItem of unitMetaItem.partyItems) {

                if (guildRankMap.get(partyItem.guildId)! > 500){
                    continue;
                }

                let matchedParties = partyItem.matchedParties.map(partyId => matchedPartyMap.get(partyId)!);

                if (partyItem.partyType === "A") {

                    if (!checkAttackParty(partyItem, matchedParties)) {
                        continue;
                    }
                } else {
                    const wipedParties = matchedParties.filter((matchedParty) => {
                        return checkAttackParty(matchedParty, [partyItem]);
                    });

                    if (wipedParties.length === 0) {
                        continue;
                    }
                }

                partyItems.push({
                    ...partyItem,
                    guildRank: guildRankMap.get(partyItem.guildId)
                });
            }

            partyItems.sort((a, b) => {
                if (props.sort === "NAME") {
                    return a.playerName.localeCompare(b.playerName);
                } else if (props.sort === "GUILD_RANK") {
                    if (a.guildRank === b.guildRank) {
                        return a.playerName.localeCompare(b.playerName);
                    } else {
                        return a.guildRank! - b.guildRank!;
                    }
                } else {
                    if (partyType === "A") {
                        if (a.stars === b.stars) {
                            return a.playerName.localeCompare(b.playerName);
                        } else {
                            return b.stars - a.stars;
                        }
                    } else {
                        if (a.matchedParties.length === b.matchedParties.length) {
                            return a.playerName.localeCompare(b.playerName);
                        } else {
                            return b.matchedParties.length - a.matchedParties.length;
                        }
                    }
                }
            });

            return partyItems;
        }
    }
});

export type Sort = "NAME" | "GUILD_RANK" | "STARS"

export type PartySortOption = {
    label: string
    value: Sort
}

export const UnitPartiesDataViewDashlet = (props: UnitPartiesDataViewDashletProps) => {
    const navigate = useNavigate();

    let selectedDate = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(filterSelectedDateAtom);
    let setSelectedDate = useSetRecoilState(filterSelectedDateAtom);

    const [wipeChecked, setWipeChecked] = useState(false);
    const [absentChecked, setAbsentChecked] = useState(false);
    const [flexChecked, setFlexChecked] = useState(false);

    let sortOptions: PartySortOption[] = [
        {
            label: "Guild Rank",
            value: "GUILD_RANK"
        },
        {
            label: "Name",
            value: "NAME"
        },
        {
            label: props.partyType === "A" ? "Stars" : "Defends",
            value: "STARS"
        }
    ];

    const [sort, setSort] = useState("GUILD_RANK");

    // @ts-ignore
    const sortItemTemplate = (props) => {
        return (
            <>
                <span>{props.label}</span>
            </>
        );
    };

    const onCalendarChange = (calendarValue: CalendarValueType): calendarValue is Date => {
        let calendarDate = calendarValue as Date;
        startTransition(() => {
            setSelectedDate(convertToLocalDateString(calendarDate));
        });
        return true;
    };

    let chartData = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(unitPartiesDataSelector({
        date: selectedDate,
        unitId: props.unitId,
        partyType: props.partyType,
        absentChecked: absentChecked,
        wipeChecked: wipeChecked,
        flexChecked: flexChecked,
        sort: sort as Sort
        // minGuildRank: debouncedMinRank,
        // maxGuildRank: debouncedMaxRank,
        // absentFilterChecked: absentChecked,
        // attackFilterChecked: attackFilterChecked,
        // defenseFilterChecked: defenseFilterChecked
    }));

    return (
        <>
            <Fieldset legend={props.title || (props.partyType === "A" ? "Attack Parties" : "Defense Parties")}>
                <div className="flex justify-content-start"
                     style={{ columnGap: "10px", rowGap: "10px", flexWrap: "wrap" }}>
                    <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                        <span className="p-inputgroup-addon">
                            Date
                        </span>
                        <ReadyDateCalendarInput date={selectedDate} onChange={onCalendarChange} />
                    </div>

                    <div className="p-inputgroup align-items-center"
                         style={{ maxWidth: "145px", columnGap: "3px" }}>
                        <span className="p-inputgroup-addon">Filters</span>
                        <WotvIcon url={"/assets/icons/toilet_paper.png"} height={22} width={30}
                                  title="1st attack failures"
                                  onClick={() => {
                                      if (!wipeChecked) {
                                          setAbsentChecked(false);
                                          setFlexChecked(false);
                                      }

                                      setWipeChecked(!wipeChecked);
                                  }} disable={!wipeChecked} />

                        {props.partyType === "A" &&
                            <div style={{ marginLeft: 5 }}>
                                <WotvIcon url={"/assets/icons/absent_icon.png"} height={22} width={22}
                                          title="Absent / missed attacks"
                                          onClick={() => {
                                              if (!absentChecked) {
                                                  setWipeChecked(false);
                                                  setFlexChecked(false);
                                              }

                                              setAbsentChecked(!absentChecked);

                                          }} disable={!absentChecked} />
                            </div>
                        }
                        <div style={{ marginLeft: 10 }}>
                            <WotvIcon url={"/assets/icons/flex_icon.png"} height={20} width={20}
                                      title="Fresh team clears"
                                      onClick={() => {
                                          if (!flexChecked) {
                                              setWipeChecked(false);
                                              setAbsentChecked(false);
                                          }

                                          setFlexChecked(!flexChecked);
                                      }} disable={!flexChecked} />
                        </div>
                    </div>
                    <div className="p-inputgroup align-items-center"
                         style={{ maxWidth: "165px" }}>
                        <span className="p-inputgroup-addon">Sort</span>
                        <Dropdown optionLabel="label" optionValue="value" value={sort} options={sortOptions}
                                  onChange={(e) => setSort(e.value)}
                                  itemTemplate={sortItemTemplate}
                                  placeholder="Sort" />
                    </div>

                </div>
            </Fieldset>
            <UnitPartiesDataView unitId={props.unitId} partyType={props.partyType} chartData={chartData!} />
        </>
    );
};