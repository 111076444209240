import * as React from "react";
import { Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import DashboardPage from "./pages/DashboardPage";
import { CircleLoader } from "react-spinners";

import PrimeReact from "primereact/api";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./App.scss";
import { Menubar } from "primereact/menubar";
import { GuildProfilePage } from "./pages/GuildProfilePage";
import { GuildBattlePage } from "./pages/GuildBattlePage";
import { PlayerProfilePage } from "./pages/PlayerProfilePage";
import { GlobalSearchBar } from "./components/util/GlobalSearchBar";
import ErrorBoundary from "./components/error/ErrorBoundary";
import { MetaPage } from "./pages/MetaPage";
import { FaqPage } from "./pages/FaqPage";
import { ContactPage } from "./pages/ContactPage";
import { LegalPage } from "./pages/LegalPage";
import { RetrievalStatusComponent } from "./components/RetrievalStatusComponent";
import { UnitProfilePage } from "./pages/UnitProfilePage";
import { UnitsPage } from "./pages/UnitsPage";
import { WotvIcon } from "./graphics/icon/WotvIcon";
import { isGlobal } from "./utilities/WotvDataUtil";
import { InputSwitch } from "primereact/inputswitch";

const App = () => {
    PrimeReact.ripple = true;

    const navigate = useNavigate();

    const items = [
        {
            label: "Dashboard",
            icon: "pi pi-fw pi-home",
            command: () => navigate("/")
        },
        {
            label: "Meta",
            icon: "pi pi-fw pi-chart-bar",
            command: () => navigate("/meta")
        },
        {
            label: "Units",
            icon: "pi pi-fw pi-users",
            command: () => navigate("/units")
        }
    ];
    const footerItems = [
        {
            label: "FAQ",
            icon: "pi pi-fw pi-question",
            command: () => navigate("/faq")
        },
        {
            label: "Contact",
            icon: "pi pi-fw pi-send",
            command: () => navigate("/contact")
        },
        {
            label: "Legal",
            icon: "pi pi-fw pi-book",
            command: () => navigate("/legal")
        }
    ];

    const start = <div className={"flex"}><img alt="logo" src="/assets/icons/wotv-stats-logo.png" height={"40"}
                                               onClick={() => {
                                                   navigate("/");
                                                   window.scrollTo({ top: 0, behavior: "smooth" });
                                               }} className="mr-2 cursor-pointer"></img>
        {/*<RetrievalStatusComponent />*/}
    </div>;

    const end = <div className={"flex align-items-center"} style={{columnGap: 3}}>
            <WotvIcon url={"/assets/icons/region_global.png"} title={"Global"}/>
            <InputSwitch checked={!isGlobal()}
                         onChange={(e) => {
                if (isGlobal()) {
                    // eslint-disable-next-line no-restricted-globals
                    history.pushState("", document.title, "/jp")
                    window.location.pathname="/jp"
                    // navigate("/")
                } else {
                    // eslint-disable-next-line no-restricted-globals
                    history.pushState("", document.title, "/")
                    window.location.pathname="/"
                }
            }}/>
            <WotvIcon url={"/assets/icons/region_japan.png"} title={"Japan"}/>
        <GlobalSearchBar /></div>;

    return (<ErrorBoundary>
            <Suspense fallback={<CircleLoader
                style={{ position: "fixed", top: "45%", left: "45%", transform: "translate(-50%, -50%)" }}
                size="200px" color={"white"} />}>
                <>
                    <header>
                        <Menubar model={items} start={start} end={end} style={{
                            width: "100%",
                            padding: "2px 6px 3px 3px",
                            textAlign: "center",
                            position: "fixed",
                            background: "#2a2d3b",
                            zIndex: 9999999
                        }} />
                    </header>
                    <div className="layout-main" style={{ padding: "55px 10px" }}>
                        <div className="layout-main-content">
                            <Routes>
                                <Route path="/" element={<DashboardPage />} />
                                <Route path="/ranking/:date" element={<DashboardPage />} />
                                <Route path="/guild/:guildId" element={<GuildProfilePage />} />
                                <Route path="/guild/:guildId/:date/:sessionId" element={<GuildBattlePage />} />
                                <Route path="/player/:playerId" element={<PlayerProfilePage />} />
                                <Route path="/unit/:unitId" element={<UnitProfilePage />} />
                                <Route path="/meta" element={<MetaPage />} />
                                <Route path="/faq" element={<FaqPage />} />
                                <Route path="/units" element={<UnitsPage />} />
                                <Route path="/contact" element={<ContactPage />} />
                                <Route path="/legal" element={<LegalPage />} />
                            </Routes>
                        </div>
                    </div>
                    <div className="layout-footer">
                        <Menubar model={footerItems} start={<></>} end={<></>} style={{
                            width: "100%",
                            padding: "2px 6px 3px 3px",
                            textAlign: "center",
                            background: "rgba(0,0,0,0)",
                            border: "none",
                            zIndex: 9999999
                        }} />
                    </div>
                </>
            </Suspense>
        </ErrorBoundary>
    );
};

export default App;
